import { LOADING } from '@bigbank/web-loco/src/store/modules/processStep/types';
import { SOURCE, pages, taskDefinitionKeys } from '@/constants';
import { isRedirectionRequired, redirectToFallbackWebLoanApplicationUrl } from '@/util/redirection';
import router from '../../../router';

const whatsNext = (store) => {
  store.commit(`processStep/${LOADING}`, false, { root: true });
};

const initializeProductRegistry = async (store, processParams) => {
  await store.dispatch('processStep/defaultPostHook', processParams, { root: true });
  await store.dispatch('products/initializeFromApplication', {}, { root: true });
};

export const customPostHooks = {
  WhatsNextPage: whatsNext,
  IdentifyOnline: async (store, processParams) => {
    const shouldRedirect = store.rootGetters['processStep/isProductCreditCardVisaClassic'] &&
      isRedirectionRequired({
        externalId: processParams.externalId,
        skipCheckingRouteName: true,
      });

    if (shouldRedirect) {
      redirectToFallbackWebLoanApplicationUrl(store.rootState.route.fullPath);

      return;
    }

    const { redirect_uri } = router.currentRoute.query;

    if (store.rootGetters['identification/isCoApplicant']) {
      await store.dispatch('processStep/defaultPostHook', {
        ...processParams,
        definitionKey: pages.spouseIdentification,
      }, { root: true });
      if (!store.rootGetters['auth/isAuthenticatedApplicant']) {
        await store.dispatch('identification/getIdentificationOptions',
          { person: store.rootGetters['processStep/coApplicantRole'] },
          { root: true });
      }
    } else {
      if (redirect_uri && redirect_uri !== store.getters.webShopRedirectUri) {
        await store.dispatch('updateApplicationWithPartnerApiRedirectUrl', { webShopRedirectUri: redirect_uri });
      }
      await initializeProductRegistry(store, processParams);
      if (!store.rootGetters['auth/isAuthenticatedApplicant']) {
        await store.dispatch('identification/getIdentificationOptions', { 'person': 'applicant' }, { root: true });
      }
    }
  },
  SubmitPersonalDetails: async (store, processParams) => {
    if ([
      SOURCE.AUTOPLIUS,
      SOURCE.DIGINET,
      SOURCE.ARUODAS,
    ].includes(store.getters.initialSource)) {
      return top.window.location.href = `${window.config.APPLICATION_URL}?externalId=${processParams.externalId}`;
    }

    await initializeProductRegistry(store, processParams);
  },
  SubmitIncomesAndLiabilities: initializeProductRegistry,
  SpouseIncomesAndLiabilities: initializeProductRegistry,
  SubmitCoBorrowerIncomesAndLiabilities: initializeProductRegistry,
  ReviewApproved: initializeProductRegistry,
  ReviewApprovedUpsale: initializeProductRegistry,
  ReviewApprovedFinal: initializeProductRegistry,
  ReviewApprovedUpsaleFinal: initializeProductRegistry,
  ReadConditionalPositiveOffer: initializeProductRegistry,
  ReviewApprovedLeasing: initializeProductRegistry,
  SubmitRealEstateInfo: initializeProductRegistry,
  [taskDefinitionKeys.ViewAndSignContract]: async (store, processParams) => {
    const isProductCreditCardVisaClassic = store.rootGetters['processStep/isProductCreditCardVisaClassic'];
    const showCreditCardOffer = store.state.taskData?.showCreditCardOffer;

    const shouldRedirect = (showCreditCardOffer || isProductCreditCardVisaClassic) &&
      isRedirectionRequired({
        externalId: processParams.externalId,
        skipCheckingRouteName: true,
      });

    if (shouldRedirect) {
      redirectToFallbackWebLoanApplicationUrl(store.rootState.route.fullPath);

      return;
    }

    await store.dispatch('processStep/defaultPostHook', processParams, { root: true });
  },
};

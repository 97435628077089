import { getFallbackWebLoanApplicationUrl, getMigratedSteps, isFeatureRedirectionToFallbackUrlEnabled } from '@/config';
import { pages } from '@/constants';

export const isRedirectionRequired = ({ externalId, routeName, prevRouteName, skipCheckingRouteName } = {}) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const isStepAlreadyMigrated = getMigratedSteps().includes(routeName);

  if (!isFeatureRedirectionToFallbackUrlEnabled() || !fallbackWebLoanApplicationUrl) {
    return false;
  }

  if (!prevRouteName && routeName === pages.createApplication && !!externalId) {
    return false;
  }

  return skipCheckingRouteName || isStepAlreadyMigrated;
};

export const redirectToFallbackWebLoanApplicationUrl = (fullPath) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const params = fullPath.substring(fullPath.indexOf('?'));

  window.location.href = `${fallbackWebLoanApplicationUrl}${params}`;
};
